<template>
    <div class="result-content" :style="backgroundStyle">
      <div class="title">
        <img :src="titleImage" :alt="result" />
      </div>
      <a v-if="isPlaying" @click="stopAudio" class="play-btn">
        <img :src="pauseButtonImage" alt="Pause" />
      </a>
      <a v-else @click="playAudio" class="play-btn">
        <img :src="playButtonImage" alt="Play" />
      </a>
      <div class="result-text">
        <img :src="resultTextImage" alt="Result" />
      </div>
      <div class="back-btn">
        <a @click="goBack">
          <img class="icon-back" :src="backButtonImage" alt="Back" />
        </a>
        <!-- <img class="icon-hand" src="../assets/hand.svg" alt="Hand" /> -->
      </div>
    </div>
  </template>
  
  <script>
  import { computed, ref, onMounted, onUnmounted } from 'vue';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';


  export default {
    name: "ResultPage",
    setup(){
      const store = useStore();
      const router = useRouter();
      const audio = ref(null);
      const isPlaying = ref(false);

      const result = computed(() => store.state.result);
      if(!result.value)
      {
        router.replace("/")
      }

      // const bgImage = require('../assets/results/' + result.value + '/bg.svg')
      const titleImage = require('../assets/results/' + result.value + '/title.svg')
      const resultTextImage = require('../assets/results/' + result.value + '/result-text.svg')
      const audioFile = require('../assets/results/' + result.value + '/audio.mp3')

      const backButtonImage = require('../assets/back-btn.svg')
      const playButtonImage = require('../assets/play-btn.svg')
      const pauseButtonImage = require('../assets/pause-btn.svg')
     
      audio.value = new Audio(audioFile);

      const backgroundStyle = computed(() => {
        return {
         // backgroundImage: `url(${bgImage})`,
        }
      })

      const goBack = () => {
        stopAudio()
        router.back(); // Go back to the previous route
      };

      const playAudio = () => {
        isPlaying.value = true
        audio.value.play()
      }

      const stopAudio = () => {
        isPlaying.value = false
        audio.value.pause()
      }

      onMounted(()=>{
        const container = document.querySelector('.result-content')
        setTimeout(()=>{
          container.classList.add('is-loaded')
        }, 200)

        audio.value.addEventListener('ended', () => {
          isPlaying.value = false
        });

      })

      onUnmounted(() => {
        stopAudio()
      })

      return{
        result,
        backgroundStyle,
        titleImage,
        backButtonImage,
        playButtonImage,
        pauseButtonImage,
        resultTextImage,
        isPlaying,
        goBack,
        playAudio,
        stopAudio
      }
    }
  };
  </script>
  
  <style scoped>
    .result-content {
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      margin-left: -1rem;
      margin-right: -1rem;
      margin-bottom: -1rem;
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    
      > *{
        opacity: 0;
        transition: opacity 0.3s;
      }
      &.is-loaded {
        > *{
          opacity: 1;
        }
      }

      .title{
        height: 75px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 2.5rem;
        padding: 0 1.25rem;
      }

      .play-btn{
        display: block;
        margin: 0 auto;
        width: 125px;
        cursor: pointer;
        height: 116px;
      }

      .back-btn{
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
      }

      .result-text{
        img{
          max-width: 320px;
        }
      }
    }

    @media screen and (max-height: 740px) {
      .result-content{
        .title{
          margin-top: 2rem;
          height: 60px;
          img{
            height: 60px;
          }
        }
        .result-text{
          img{
            max-width: 280px;
          }
        }
        .play-btn{
          width: 110px;
          height: 98px;
        }
        
      }
    }
  </style>
  
import { createStore } from "vuex";

const store = createStore({
  state: {
    result: null,
  },
  mutations: {
    setResult(state, newResult) {
      state.result = newResult;
    },
  },
  actions: {
    updateResult({ commit }, newResult) {
      commit("setResult", newResult);
    },
  },
  getters: {
    result(state) {
      return state.result;
    },
  },
});

export default store;

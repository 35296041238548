<template>
    <div class="action-sheet" :class="{'is-shown' : show}">
      <div class="content">
        <div class="header">
          <span>Installing the Kinky Land App</span>
          <a @click="handleHideClick" class="close"><img src="../assets/close-icon.svg" alt="X"/></a>
        </div>
        <div class="body">
          <p class="stroke">Follow the instructions below to install</p>
          <ul>
            <li v-if="device == 'android'"><strong>Step 1:</strong><span>Select <img class="menu-icon menu-icon-android" src="../assets/android-menu-icon.png" alt="menu"/> on the top right menu</span></li>
            <li v-if="device == 'ios'"><strong>Step 1:</strong><span>Select <img class="menu-icon" src="../assets/ios-menu-icon.png" alt="menu"/> on the bottom menu</span></li>
            <li v-if="device == 'desktop'"><strong>Step 1:</strong><span>Click Install App icon on the top right corner of address bar</span></li>
            <li><strong>Step 2:</strong><span v-if="device == 'desktop'">Choose <strong>&apos;Install&apos;</strong> from the popup</span><span v-else>Scroll down and Select <strong>&apos;Add to Home Screen&apos;</strong></span></li>
            <li><strong>Step 3:</strong><span>Select the <img class="tl-icon" src="../assets/tl-small-icon.png" alt="menu"/> shortcut on the <span v-if="device == 'desktop'">Desktop</span><span v-else>Home Screen</span> to launch the App.</span></li>
          </ul>
        </div>
      </div>
      <div  @click="handleHideClick" class="overlay"></div>
    </div>
</template>

<script>
  export default {
    name: "InstallInstructions",
    props: {
      show: {
        type: Boolean,
        required: true
      },
      onHide: {
        type: Function,
        required: true
      },
      device: {
        type: String,
        required: false
      }
    },
    watch: {
        show(newValue) {

          const content = document.querySelector(".action-sheet")
          if(newValue === true){
            setTimeout(()=>{
              content.classList.add('is-loaded')
            }, 20)
          }
          else{
            content.classList.remove('is-loaded')
          }
      }
    },
    setup(props){

      const handleHideClick = () => {
        props.onHide()
      }

      return {
        handleHideClick
      }
    }
  };
</script>

<style scoped>
.action-sheet {
  display: none;
  color: #000000;
  
  &.is-shown{
    display: block;
  }
  .overlay{
    position: fixed;
    top: 0;
    height: 100%;
    left: 0;
    width: 100%;
    transition: opacity 0.3s;
    z-index: 1;
    background-color: rgba(0,0,0,0.5);
    opacity: 0;
  }

  &.is-loaded{
    .overlay{
      opacity: 1;
    }
    .content{
      bottom: 0;
    }
  }

  .content{
    position: fixed;
    background-color: #fff;
    z-index: 9;
    width: 100%;
    bottom: -300px;
    left: 0;
    padding: 1rem;
    transition: 0.3s;
    min-height: calc(100vh - 50%);
    min-height: calc(100svh - 50%);

    .stroke{
      border-bottom: 1px solid #929292;
      padding-bottom: 0.7rem;
    }

    .header{
      font-size: 1.2rem;
      font-weight: bold;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #929292;
      padding-bottom: 0.7rem;

      .close{
        max-width: 18px;
      }
    }

    .body{
      ul{
        padding: 0;
        margin: 0;
        list-style: none;

        li{
          display: flex;
          margin-bottom: 0.8rem;

          &:last-child{
            margin-bottom: 0;
          }

          > strong{
            width: 100px;
            flex: 0 0 70px;
            line-height: 1.5;
          }
          > span{
            line-height: 1.5;
            > img{
              display: inline-block;
              &.menu-icon{
                height: 20px;
                position: relative;
                top: 2px;
                margin: 0 1px;
              }
              &.menu-icon-android{
                height: 16px;
              }
              &.tl-icon{
                height: 18px;
                position: relative;
                top: 3px;
                margin: 0 1px;
              }
            }
            
          }
        }
      }
    }
  }
}
</style>
import { createRouter, createWebHistory } from "vue-router";
import HomePage from "@/pages/HomePage.vue";
import ResultPage from "@/pages/ResultPage.vue";
import InstallPage from "./pages/InstallPage.vue";

const routes = [
  {
    path: "/",
    name: "InstallPage",
    component: InstallPage,
  },
  {
    path: "/home",
    name: "HomePage",
    component: HomePage,
  },
  {
    path: "/result",
    name: "ResultPage",
    component: ResultPage,
  },
  // Add other routes here if needed
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

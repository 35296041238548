<template>
    <div class="logo-wrapper">
      <img class="logo" src="../assets/logo.svg" />
    </div>
</template>

<script>
  export default {
    name: "AppLogo"
  };
</script>

<style scoped>
  .logo-wrapper{
    text-align: center;
    height: 100px;
    img{
      max-height: 100px;
    }
  }
  @media screen and (max-height: 568px){
    .logo-wrapper{
      height: 75px;
      img{
        max-height: 75px;
      }
    }
  }
</style>
